export const colors = {
  green: '#66B28A',
  khaki: '#FAF8F0',
  khakiDark: '#E5E7DB',
  grayLighter: '#E0E0E0',
  grayLight: '#757575',
  khakiOpacity: '#FAF8F0B3',
  darkOpacity: '#0000001A',
  gray: '#9E9E9E',
  grayMidLight: '#BEBEBE',
  grayDarker: '#424242',
  grayDarkerOpacity: '#424242B3',
  red: '#D04F45',
  grayOpacity: '#00000033',
  transparent: '#00000000',
  claimable: '#A5A5A5',
  pink: '#D893BA',
  claimed: '#72B791',
  yellow: '#d9caa9',
  black: '#1a1a1a',
  success: '#66B28A',
  error: '#D04F45',
  warning: '#d9caa9',
  white: '#FFF',
  info: '#424242',
  smShadow: {
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowColor: '#000',
    shadowOpacity: 0.2,
    shadowRadius: 3,
    shadowBlur: 4,
    elevation: 2,
  },
  boxInBoxShadow: {
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowColor: '#000',
    shadowOpacity: 0.2,
    shadowRadius: 3,
    shadowBlur: 4,
    elevation: 0.0001,
  },
  placeholder: '#C7C7CD',
  loadingOpacity: '#00000022',
  tooBlack: '#000',
  modalBg: '#00000066',
};
